import React from "react"
import { Layout } from "../components/Layout";

const NotFound = () => (
  <Layout>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFound
